import React from "react"
import BlogTitle from "../../universal/components/BlogTitle"
import BlogBigCard from "../../universal/components/BlogBigCard"
import BlogSmallCards from "../../universal/components/BlogSmallCards"
import InfoContainer from "../../universal/components/InfoContainer"
import { connect } from "react-redux"
import "./index.scss"
import "../root_anims.scss"
import EmptyBlogPost from "../../universal/components/EmptyBlogPost"

const BlogPageContent = ({ blogsList }) => {
  return (
    <div className="blog-page-container">
      {blogsList?.length ? (
        <>
          <BlogTitle />
          <BlogBigCard />
          <BlogSmallCards />
          <InfoContainer />
        </>
      ) : (
        <EmptyBlogPost />
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    blogsList: state.strapiReducer.blogsList,
  }
}

export default connect(mapStateToProps, null)(BlogPageContent)
