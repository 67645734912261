import React from "react"
import BlogCards from "../BlogCards"
import "./index.scss"

const BlogSmallCards = () => {
  return (
    <div className="blog-small-cards-holder">
      <BlogCards />
    </div>
  )
}

export default BlogSmallCards
