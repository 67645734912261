import React, { useEffect, useState } from "react"
import strapi from "../api/strapi"
import Header from "../browser/components/Header"
import Footer from "../universal/components/Footer"
import BlogPageContent from "../views/BlogPageContent"
import Helmet from "react-helmet"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getBlogs } from "../redux/actions/strapiActions"
import Loader from "../universal/components/Loader"
import { showCookieBanner } from "../helpers/functions"
import Cookies from "../universal/components/Cookies"

const Blog = ({ getBlogs, blogsList }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    })

    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responsePageData = await strapi.get("/blogs")
        getBlogs(responsePageData.data)

        document.title = "Dalmatiko osiguranje | Blog"
      } catch (err) {
        console.log(err.message)
      }
    }
    fetchData()
  }, [getBlogs])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dalmatiko | Blog</title>
        <link rel="canonical" href="https://dalmatiko-osiguranje.hr/blog" />
        <meta
          name="description"
          content="Blog stranice Dalmatiko osiguranja. Saznaj novosti i nove ponude za osiguranje. Nauči kako se kvalitetno i jeftino osigurati."
        />
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <BlogPageContent />
          {showCookieBanner() ? <Cookies /> : null}
          <Footer />
        </>
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    blogsList: state.strapiReducer.blogsList,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getBlogs: bindActionCreators(getBlogs, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog)
