import React from "react"
import { Link } from "gatsby"
import "./index.scss"

import { connect } from "react-redux"
import { gaBlogClickEvent } from "../../../helpers/functions/googleAnalytics"

const BlogBigCard = ({ blogsList }) => {
  return (
    <Link
      to={`/blog/${blogsList[0]?.Slug}`}
      className="blog-link"
      style={{ cursor: "pointer" }}
      onClick = {() => gaBlogClickEvent(`Klik na veliki blog (${blogsList[0]?.Slug}) sa blog stranice`)}
    >
      <div className="blog-big-card-holder">
        <div className="left-card">
          <h4 className="left-card-title1">{blogsList[0]?.Kratki_Naslov}</h4>
          <h2 className="left-card-title2">{blogsList[0]?.Naslov}</h2>
        </div>
        <div className="right-card">
          <img
            src={`https://api.trebamosiguranje.hr${blogsList[0]?.Slika?.url}`}
            alt="Blog Image"
            className="blog-image"
            onLoad={e => e.target.classList.add("active")}
          />
        </div>
      </div>
    </Link>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    blogsList: state.strapiReducer.blogsList,
  }
}

export default connect(mapStateToProps, null)(BlogBigCard)
